import { Chip, Grid, Paper, Typography } from "@mui/material";
import React, { ReactNode } from "react";

import Logo from "../../assets/TextelLogo_192.png";

const LoginCard: React.FC<{ children?: ReactNode }> = ({ children }: { children?: ReactNode }) => {
  return (
    <Paper sx={{ height: "16rem", width: "30rem", padding: "1rem 2rem 1rem 2rem" }} elevation={3}>
      <Grid
        container
        direction="column"
        alignItems="center"
        wrap="nowrap"
        justifyContent="space-between"
        sx={{ height: "100%" }}
        spacing={2}
      >
        <Grid container item justifyContent="center" alignItems="center" spacing={2} wrap="nowrap">
          <Grid item>
            <img style={{ width: "6rem" }} src={Logo} alt="test icon" />
          </Grid>
          <Grid item>
            <Typography variant="h4">Textel Configuration</Typography>
          </Grid>
        </Grid>
        <Grid item container xs alignItems="center">
          {children}
        </Grid>
        <Grid item>
          <Chip label={`${process.env.REACT_APP_ENVNAME} - ${process.env.REACT_APP_VERSIONNO}`} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoginCard;
