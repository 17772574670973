import React, { createContext, useEffect, useState } from "react";
import { MsalSessionToken, Token, useMsalRefresh } from "sonobello.utilities.react.msal";

interface AppContextProps {
  /** See {@link Token}. */
  token: Token;
  /** Callback to execute an update of the token. */
  refreshToken: () => void;
}

/** Get the first value from the session store which has a key matching the supplied regex. */
const queryFromSession = <T extends Record<string, unknown>>(key: string, value: unknown): T | undefined => {
  const count = sessionStorage.length;
  for (let x = 0; x < count; x++) {
    const storeKey = sessionStorage.key(x);
    if (!storeKey) continue;
    const storeValue = JSON.parse(sessionStorage.getItem(storeKey) as string);
    try {
      if (storeValue[key] === value) return storeValue as T;
    } catch {
      return undefined;
    }
  }
  return undefined;
};

const tenantId = process.env.REACT_APP_MSAL_TENANT_ID;
if (!tenantId) throw "The msal tenant id environment variable is missing or empty.";
const applicationId = process.env.REACT_APP_MSAL_APP_ID;
if (!applicationId) throw "The msal application id environment variable is missing or empty.";
const scopes = [`${process.env.REACT_APP_MSAL_AUTHENTICATION_SCOPE}`];

const AppContext = createContext({} as AppContextProps);

export const AppContextProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element => {
  const [token, setToken] = useState(() => {
    const sessionToken = queryFromSession<MsalSessionToken>("credentialType", "AccessToken");
    if (!sessionToken?.expiresOn || !sessionToken?.secret) {
      sessionStorage.clear();
      location.reload();
    }
    return Token.fromMsalSessionToken(sessionToken as MsalSessionToken);
  });

  const [refreshToken, triggerRefresh] = useMsalRefresh({ token, applicationId, scopes });
  useEffect(() => setToken(refreshToken), [refreshToken]);

  return <AppContext.Provider value={{ token, refreshToken: triggerRefresh }}>{children}</AppContext.Provider>;
};

export default AppContext;
