import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import { Navigate, Route } from "react-router-dom";
import { Hidden } from "sonobello.utilities.react.mui";

import TextelDispositions from "./Tools/TextelDispositions";
import ZenotiDispositions from "./Tools/ZenotiDispositions";

export interface AdminTool {
  setIsContentLoading: (state: boolean) => void;
}

interface DrawerListItem {
  /** The label of the tool to be displayed in the drawer toolbar. */
  text: string;
  /** The icon of the tool to be rendered in the drawer toolbar. */
  icon?: JSX.Element;
  /** The route to be used when the tool is clicked on in the drawer toolbar. */
  route: string;
}

const drawerItems: DrawerListItem[] = [
  { text: "Textel Dispositions", icon: <PermPhoneMsgIcon />, route: "/TextelDispositions" },
  { text: "Zenoti Dispositions", icon: <SupportAgentIcon />, route: "/ZenotiDispositions" }
];
/** Renders the administration view and its multiple child tool views. */
const Main: React.FC = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [isContentLoading, setIsContentLoading] = useState(false);
  const onClickTool = (route: string) => {
    setIsContentLoading(true);
    navigation(route);
  };
  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <Drawer variant="permanent" sx={{ width: 265 }}>
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {drawerItems.map((item, key) => (
              <ListItem
                button
                selected={location.pathname === item.route}
                key={`${key}`}
                onClick={() => onClickTool(item.route)}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ display: "flex", flexGrow: 1, p: 2, height: "100%" }}>
        <Hidden sx={{ display: "flex", flexGrow: 1, height: "100%" }} show={!isContentLoading}>
          <Routes>
            <Route
              path="TextelDispositions"
              element={<TextelDispositions setIsContentLoading={setIsContentLoading} />}
            />
            <Route
              path="ZenotiDispositions"
              element={<ZenotiDispositions setIsContentLoading={setIsContentLoading} />}
            />
            <Route
              path="/"
              element={
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ m: 0, width: "100%", height: "100%" }}
                >
                  <Typography variant="body1">Please select a tool from the list to get started.</Typography>
                </Grid>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Hidden>
        <Hidden sx={{ flexGrow: 1, height: "100%" }} show={isContentLoading}>
          <Grid
            direction="column"
            container
            item
            xs
            alignItems="center"
            justifyContent="center"
            sx={{ m: 0, width: "100%", height: "100%" }}
          >
            <CircularProgress size="5rem" />
          </Grid>
        </Hidden>
      </Box>
    </Box>
  );
};

export default Main;
