import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { Box, CssBaseline, ThemeProvider, Toolbar } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { buildMsalInstance } from "sonobello.utilities.react.msal";

import Theme from "./assets/Theme";
import { AppContextProvider } from "./components/AppContext";
import Login from "./components/Login/Login";
import Main from "./components/Main";
import Navigation from "./components/Navigation";

const tenantId = process.env.REACT_APP_MSAL_TENANT_ID;
if (!tenantId) throw "The msal tenant id environment variable is missing or empty.";
const applicationId = process.env.REACT_APP_MSAL_APP_ID;
if (!applicationId) throw "The msal application id environment variable is missing or empty.";
const msalInstance = buildMsalInstance(tenantId, applicationId);

const App: React.FC = () => (
  <MsalProvider instance={msalInstance}>
    <ThemeProvider theme={Theme}>
      <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} maxSnack={3}>
        <CssBaseline />
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate to="login" />} />
          </Routes>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <AppContextProvider>
            <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
              <Navigation />
              <Toolbar />
              <Box sx={{ height: "100%", width: "100%" }}>
                <Main />
              </Box>
            </Box>
          </AppContextProvider>
        </AuthenticatedTemplate>
      </SnackbarProvider>
    </ThemeProvider>
  </MsalProvider>
);

export default App;
