import SortIcon from "@mui/icons-material/Sort";
import { Button, Chip, CircularProgress, Grid, ListItemIcon, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  AdminFormLabel,
  SelectList,
  SelectListItem,
  SelectListItemProps,
  ValidatedTextField
} from "sonobello.utilities.react.mui";

import { TextelDisposition } from "../../models/TextelDisposition";
import useTextel from "../../utils/UseTextel";
import { AdminTool } from "../Main";
import ToolWrapper from "../ToolWrapper";

const toolReadme = `The Textel Dispositions tool allows you to review and modify the code for Textel Dispositions.`;
const crmDispositionReadme = `Select a Textel Disposition to configure.`;
const configurationLabelReadme = `Modify disposition's code.`;

const TextelDispositions: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dispositions, setDispositions] = useState<TextelDisposition[]>([]);
  const [selectedDisposition, setSelectedDisposition] = useState<TextelDisposition>();
  const [listComparator, setListComparator] = useState<"name" | "code">("name");

  // Get Textel Dispositions
  const { res: getResponse, loading: getLoading } = useTextel<TextelDisposition[]>("Get Textel Dispositions", {
    url: "TextelDispositions"
  });
  useEffect(() => setDispositions(getResponse?.payload.sort(TextelDisposition.nameComparator) || []), [getResponse]);

  // #region Update Permissions
  const {
    res: updateResponse,
    loading: updateLoading,
    setReq: setUpdateRequest
  } = useTextel<TextelDisposition, TextelDisposition>("Update Textel Disposition", { method: "put" });
  useEffect(() => {
    if (updateResponse) {
      enqueueSnackbar("Disposition Updated", { variant: "success" });
      setSelectedDisposition(undefined);
      setDispositions(oldDispositions => {
        const index = oldDispositions.findIndex(d => d.id === updateResponse.payload.id);
        oldDispositions[index] = updateResponse.payload;
        return [...oldDispositions];
      });
    }
  }, [updateResponse]);
  // #endregion

  //#region Actions
  const onSaveChanges = () =>
    setUpdateRequest(
      r => r && { ...r, url: `TextelDispositions/${selectedDisposition!.id}`, payload: selectedDisposition }
    );
  //#endregion

  // show spinner while loading
  useEffect(() => setIsContentLoading(getLoading), [getLoading]);

  // Actions
  const toggleComparator = () =>
    setListComparator(oldComparator => {
      setDispositions(old =>
        old.sort(oldComparator === "name" ? TextelDisposition.codeComparator : TextelDisposition.nameComparator)
      );
      return oldComparator === "name" ? "code" : "name";
    });

  return (
    <ToolWrapper title="Textel Dispositions" readme={toolReadme} sx={{ minWidth: "50rem" }}>
      <Grid container item xs spacing={2} sx={{ height: "100%" }}>
        <Grid item xs>
          <Grid container direction="column" spacing={1} sx={{ height: "100%" }} wrap="nowrap">
            <Grid item container justifyContent="space-between">
              <Grid item>
                <AdminFormLabel label="Textel Dispositions" readme={crmDispositionReadme} />
              </Grid>
              <Grid item>
                <Tooltip title="Toggle Sorting">
                  <Button variant="text" startIcon={<SortIcon />} onClick={toggleComparator}>
                    {listComparator === "name" ? "By Name" : "By Code"}
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs sx={{ overflow: "auto" }}>
              <SelectList
                items={dispositions}
                value={selectedDisposition || null}
                disabled={getLoading}
                isItemEqualToValue={(i1, i2) => i1.id === i2.id}
                onChange={newValue => setSelectedDisposition(newValue || undefined)}
                renderItem={TextelDispositionItem}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ minWidth: "27rem" }}>
          <Grid container direction="column" spacing={2}>
            <Grid item container>
              <AdminFormLabel
                label={`${selectedDisposition?.name || ""} Configuration`}
                readme={configurationLabelReadme}
              />
            </Grid>
            <Grid item>
              <ValidatedTextField
                disabled={!selectedDisposition}
                label="Code"
                value={selectedDisposition?.textelCode || ""}
                onChange={v => setSelectedDisposition(d => d && { ...d, textelCode: v ? Number(v) : 0 })}
                sx={{ minWidth: "23rem" }}
              />
            </Grid>
            <Grid item container justifyContent="center">
              {updateLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={
                    !selectedDisposition ||
                    JSON.stringify(dispositions.find(d => d.id === selectedDisposition.id)) ===
                      JSON.stringify(selectedDisposition) ||
                    !Number(selectedDisposition.textelCode)
                  }
                  onClick={onSaveChanges}
                >
                  Save Changes
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ToolWrapper>
  );
};

const TextelDispositionItem: React.FC<SelectListItemProps<TextelDisposition>> = ({ item: disposition, ...rest }) => (
  <SelectListItem {...rest} label={disposition => disposition.name} item={disposition}>
    <ListItemIcon sx={{ ml: 2 }}>
      <Chip size="small" label={`Code ${disposition.textelCode}`} />
    </ListItemIcon>
  </SelectListItem>
);

export default TextelDispositions;
