import { useMsal } from "@azure/msal-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { AppBar, Badge, Grid, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import React from "react";

import Logo from "../assets/TextelLogo_NoLeaf_192.png";

/** The interface for the MSAL object which is not provided by the native library.  */
interface MsalSecret {
  idTokenClaims: { name: string };
}

const Navigation: React.FC = () => {
  const { accounts, instance } = useMsal();
  const userFullName = accounts?.length ? (accounts[0] as MsalSecret).idTokenClaims.name : undefined;

  return (
    <AppBar sx={theme => ({ zIndex: theme.zIndex.drawer + 1 })}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Grid item>
          <Badge
            color="secondary"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={process.env.REACT_APP_VERSIONNO}
          >
            <img src={Logo} style={{ width: "2.5rem", marginTop: "-0.7rem" }} />
          </Badge>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={2} wrap="nowrap">
            <Grid item container alignItems="center">
              <Grid item>
                <AccountCircleIcon color="secondary" />
              </Grid>
              <Grid item>
                <Typography color="secondary" variant="body1">
                  <strong>{userFullName}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Tooltip title="Log Out">
                <IconButton color="secondary" onClick={() => instance.logoutPopup().then(() => sessionStorage.clear())}>
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
